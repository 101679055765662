<template>
  <page-content id="new-event-forms-view">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(validateForm)">
        <v-card>
          <v-card-text>
            <v-row>
              <v-chip-group active-class="" mandatory v-model="templateIndex">
                <v-chip
                  v-for="(template, i) in templates"
                  :key="i"
                  class="ma-2"
                  :color="template.color"
                  filter
                  :disabled="locked"
                  :input-value="true"
                  :text-color="template.textColor"
                  large
                >
                  <v-icon left class="pa-2"> {{ template.image }} </v-icon>
                  {{ template.name }}
                </v-chip>
              </v-chip-group>
            </v-row>

            <form-field label="Obiekt">
              <sites-combo
                :disabled="locked"
                :site="site"
                autofocus
                @site-selected="siteSelected"
              />
            </form-field>
            <form-field :label="selectedTemplate.rangeLabel">
              <v-row>
                <v-col dense cols="12" md="6" lg="4" xl="3">
                  <VueCtkDateTimePicker
                    :disabled="locked && !isFuture()"
                    v-bind="$attrs"
                    v-model="beginAt"
                    no-clear-button
                    color="#f64e65"
                    :hint="selectedTemplate.startLabel"
                    label="Wybierz datę i godzinę"
                    format="YYYY-MM-DD HH:mm"
                    formatted="dddd, DD MMMM YYYY HH:mm"
                  >
                  </VueCtkDateTimePicker>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <VueCtkDateTimePicker
                    :disabled="locked && isPast()"
                    v-bind="$attrs"
                    v-model="expiresAt"
                    color="#f64e65"
                    :hint="selectedTemplate.endLabel"
                    :minDate="minExpiresAt"
                    label="Do odwołania"
                    format="YYYY-MM-DD HH:mm"
                    formatted="dddd, DD MMMM YYYY HH:mm"
                  ></VueCtkDateTimePicker>
                </v-col>
              </v-row>
            </form-field>

            <form-field label="">
              <users-combo
                :usersSelected="reported"
                @user-selected="reportedSelected"
                :label="$t('user.reported')"
                :multiple="false"
                :users="siteMembers"
              ></users-combo>
            </form-field>

            <form-field label="Informacje dodatkowe">
              <validation-provider v-slot="{ errors }" name="Treść">
                <v-text-field
                  outlined
                  v-model="message"
                  :error-messages="errors"
                  hide-details="auto"
                  color="secondary"
                />
              </validation-provider>
            </form-field>

                <app-card-actions>
                  <cancel-btn @click="$router.back()" />
                  <apply-btn :disabled="!validate" :loading="saving" default type=submit />
                </app-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </validation-observer>
  </page-content>
</template>

<script>
import { Note } from "@/store/modules/note";
import SiteService from "@/services/site.service";
import EventService from "@/services/event.service";
import { extend } from "vee-validate";
import { addHours } from "date-fns";
// import moment from "moment"
import _ from "lodash";
import { required } from "vee-validate/dist/rules";

export default {
  name: "NewNoteForm",
  beforeMount() {
    // const note = this.$route.params.note
    // if (note) {
    //   this.note = note
    //   this.site = note.site
    // }
    this.selectedTemplate = this.templates[0];
    this.updateForm();
    if (this.$route.params.noteId) {
      this.locked = true;
      SiteService.getNote(this.$route.params.noteId).then((note) => {
        this.note = note;
        this.message = this.note.content?.message;
        this.beginAt = this.$options.filters.localDateTime(
          this.note.beginning_at
        );
        if (this.note.expires_at) {
          this.expiresAt = this.$options.filters.localDateTime(
            this.note.expires_at
          );
        } else {
          this.expiresAt = null;
        }
        this.site = this.note.site;
      });
    } else if (this.$route.query.site) {
      this.site = this.$route.params.site;
      if (!this.site) {
        SiteService.get(this.$route.query.site).then((response) => {
          this.site = response.data;
        });
      }
    }
  },
  mounted() {
    extend("required", {
      ...required,
    });
  },
  watch: {
    templateIndex() {
      this.updateForm();
    },
    beginAt(value) {
      this.updateExpiresAt(value, this.selectedTemplate?.duration);
    },
  },
  data: () => ({
    site: null,
    message: "",
    saving: false,
    beginAt: null, //new Date(),
    expiresAt: null,
    minExpiresAt: null,
    locked: false,
    templates: Note.all(),
    templateIndex: 0,
    selectedTemplate: {},
    reported: null,
    siteMembers: new Promise((resolve) => {
      return resolve([]);
    }),
  }),
  computed: {
    validate() {
      return this.site
    },
  },
  methods: {
    updateExpiresAt(beginAt, duration) {
      if (duration == null) {
        this.expiresAt = null;
      } else {
        if (!this.$route.params.noteId) {
          this.expiresAt = this.$options.filters?.localDateTime(
            addHours(new Date(beginAt), duration)
          );
        }
      }
      this.minExpiresAt = beginAt;
    },
    isFuture() {
      return Note.isFuture({ beginning_at: this.be });
    },
    isPast() {
      return Note.isPast(this.note);
    },
    siteSelected(site) {
      this.site = site;
      let selected = null;
      this.siteMembers = new Promise((resolve) => {
        SiteService.fetchMembers(site.id).then((data) => {
          let users = [];
          for (const record of data) {
            users.push(record.user);
            if (record.user.id == this.note?.content?.reported_by?.id) {
              selected = record.user;
            }
          }
          resolve(users);
          this.reported = selected;
        });
      });
    },
    reportedSelected(member) {
      if (_.isString(member)) {
        member = { id: 0, full_name: member };
      }
      this.reported = member;
    },
    validateForm() {
      this.saving = true;
      let note = {
        id: this.$route.params.noteId,
        siteId: this.site.id,
        category: this.selectedTemplate.id,
        beginningAt: new Date(this.beginAt),
        expiresAt: this.expiresAt ? new Date(this.expiresAt) : null,
        message: this.message,
        reportedBy: this.reported
      };
      if (note.id) {
        SiteService.updateNote(note).then(() => this.$router.back());
      } else {
        const p1 = SiteService.createNote(note);
        let p2 = 1;
        if (this.selectedTemplate.code) {
          let info;
          if (this.expiresAt) {
            info = `Od: ${this.beginAt} Do: ${this.expiresAt}`;
          } else {
            info = `Od: ${this.beginAt}`;
          }
          p2 = EventService.create({
            siteId: this.site.id,
            code: this.selectedTemplate.code,
            userReported: this.reported,
            info: info,
          });
        }
        Promise.all([p1, p2]).then(() => this.$router.back());
      }
    },
    updateForm() {
      this.selectedTemplate = this.templates[this.templateIndex];
      this.beginAt = this.$options.filters?.localDateTime(new Date());
      this.updateExpiresAt(this.beginAt, this.selectedTemplate?.duration);
    },
  },
};
</script>
